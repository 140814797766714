
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans/loans.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { TrackprogressService } from 'src/app/services/trackprogress/trackprogress.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';

//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';

import { DatePipe } from '@angular/common';
import { contains } from 'jquery';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { LenderService } from 'src/app/services/lender/lender.service';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
@Component({
      providers: [DatePipe],

      selector: 'app-user-ctwadetails',
      templateUrl: './user-ctwadetails.component.html',
      styleUrls: ['./user-ctwadetails.component.scss']
})
export class UserCTWADetailsComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("frame1", {static: true}) frame1: ModalDirective;
  @ViewChild("frame2", {static: true}) frame2: ModalDirective;
  @ViewChild("docRecive", {static: true}) docRecive: ModalDirective;
  @ViewChild("docVeryFiNo", {static: true}) docVeryFiNo: ModalDirective;
  @ViewChild("docVeryFiYes", {static: true}) docVeryFiYes: ModalDirective;
  @ViewChild("feedbackquate", {static: true}) feedbackquate: ModalDirective;
  @ViewChild("docView", {static: true}) docView: ModalDirective;
  @ViewChild("docCat", {static: true}) docCat: ModalDirective;
  @ViewChild("docReject", {static: true}) docReject: ModalDirective;

  
  headElements = [
    
     
    {
     name:"Doc Name",
     sortBy:"Document_Name"
   },
    {
      name:"Doc Type",
      sortBy:"Doc_Type"
    },     
    {
      name:"Doc Comment",
      sortBy:"Doc_c"
    },
    {
      name:"Date",
      sortBy:"Date"
    },
    {
      name:"View",
      sortBy:"View"
    }


  ];
  fullerton_lender_id:  number=0;
  idfc_lender_id:  number=0;
  loantab_lender_id:  number=0;
  public radioModel: string = 'Left';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  Clicked : boolean
  @ViewChild("datePicker", {static: true}) darkPicker: MDBDatePickerComponent;
  darkClock: any;
  user:any;
  subs: Subscription;
  sub: any;
  data: any;
  showLoader: boolean;
  decryptData: any;
  leadDetails: any=[];
  elements: any=[];
  elements_feedback: any=[];
  elementsfeedback: any=[];
  meraemiStage: any=[];
  showDocuments:boolean=false;
  custsD = {};
  driveapi = {};
  cust: any;
  ProposedEmi: number;
  foir: number;
  e_emi: number;
  interest_rate: number;
  isTrackProgress:boolean=true;
  trackData= {};
  trxStatus: any;
  trxSubStatus: any;
  requestJsonDisabled:boolean=false;
  responseJsonDisabled:boolean=false;
  trackProgressJsonDisabled:boolean=false;
  iapstage: string;
  mapstage:string;
  iap_stage_color: string;
  eligstage: string;
 
  elig_stage_color: string;
  userdetils: {};
  //registerForm: any;
  registerForm: FormGroup;
  useridSubmitted: boolean;
  quateSubmitted: boolean;
  usercrmSubmitted: boolean;
  FileRejectSubmitted: boolean;
  from_user_id: any;
  message: any;
  user_id: any;
  feedForId: any;
  display: string;
  name = "Angular CLI: 11.2.1,  Node: 13.14.0,  OS: win32 x64";
  checkFormArray: Array<any> = [];
  
  // categories = [ 
  //   {"checked": true, name :"Active", id: 1},
  //   {"checked": false, name :"Cancel", id: 2},
  //   {"checked": false, name :"Transfer", id: 3},
    
  // ];
  followupdate: any;
  interactiondate: any;
  status: any;
  followuptime: any;
  today= new Date();
  jstoday = '';
  popupaddBtn: number;
  mesg: string;
  messagediv: number;
  checkAction: string;
  fedbacklength: number=0;
  fedbackStage: any;  
  currentdate: string;
  userdate: string;
  rolecode: any;
  popupbtnactive: any;
  loginAdmin: any;
  loanid: any;
  elig_status: string;
  eligStatus: any;
  feedback_Stage: any;

  activeClass: string;
  valuemain: number=0;
  userid: number=0;
  tabdiv: number=0;
  isValue: number = 0;
  idfc: number=0;
  fullerton: number=0;
  document: number=0;
  stage: number=0;
  aapstage: number=0;
  
  lenderResponse: any = [];

  sub_lender_Response: any = [];
  min_lender_Response: any = [];
  lenderMainArrya: any[];
  iapstageBackColour: string;
  datesarray: any=[];
  re_active: Date;
  re_active_feedback: any=[];
  reactive_stage: any;
  ConsentArray: any=[];
  criteriafail: any=[];
  eligStage: string;
  eligArray: any=[];
  lenderStage: any=[];
  quest_label: any;
  quest_value_text: any;
  custError: any= [];
  ErrorDetils= {};
  
  eligError: {};
  eligerrorstage: number=0;
  oldfeedbank: number=0;
  eligcheck: number=0;
  remainingText: number=0;
  iapstageBack_Colour: string;
  tabval: number;
  title: any;
  imageur: string;
  css: string;
  jsonresp: number=0;
  provider: string;
  logindate: any;
  loindateExp: number=0;
  kysdocback: string;
  lenderOfflineResponse: number=0;
  mapiapstage: string;
  trx_status: string;
  useid: any;
  lenderExcelldown: string;
  idfcbackcolour: string;
  criteriafailstage: number=0;
  criteriafailaapstage:string;
  dropoffdate:string;
  sys_user_dropoffs_msg:string;
  transaction_stage:string;
  bot_stage_name: string;
  userdetails: any;
  question: any;
  answer: any;
  lenderapiFail: string;
  lenderapiFailstatus: number=0;
  whatstage: number=0;
  adminname: any;
  whatssenduserdate: any;
  whatstatus: any;
  whatsapmessage: any;
  interaktstage: number=0;
  interaktstagependingElig: number=0;
  googledrive : any=[];
  googledriveErrorMsg : any=[];
  googledriveDeleteDocErrorMsg : any=[];
  listofdeletdocComp: any=[];
  deleteDocErrorMsg : any=[];
  drivelength: number=0;
  usewebContentLinkid: any;
  IapInteraktstage: number=0;
  EligInteraktstage: number=0;
     whataapelig: number=0;
     mobileintr: string;
     interaktIapPending : number=0;
     IapPenndInteraktstage: number=0;
     FeedbackPopup: number=0;
     transfertabopen: number=0;
      optionsmeraemistage: { value: string; label: string; }[];
      GetDocReceivedlist: { value: string; label: string; }[];
      crmdocveryfi: { value: string; label: string; }[];
       optionsArray: any = []; 
       lenderArray: any = []; 
   messages: any = []; 
   feedbackProductname: string;
feedbackLendername: number=0;
getProduct: any = []; 
getfileError: any = []; 
productArray: any = [];
listfileError: { value: string; label: string; }[];
  meraemistage:   any=[];
  meraemi_stage: any;
  feedbackdatepikerdisable: number=0;
  doc_recevied: number;
  usertransfer: any= []; 
  mainlenderarrayfilter: any= []; 
  adid: string;
  userdetilspage:{};
  loantabuserid: any;
  loantabloan_code: any;
  loantabloan_id: any;
  loantabdiv: number=0;
  adminid: any;
  loantabapishow: number=0;
  crmStage: string;
  crmDate: string;
  appstage: string;
  substagelender: string;
  appstagedate: any;
  appstageflu: string;
  substagelenderflu: string;
  appstagedateflu: string;
  appstageLoantap: string;
  substagelenderLoantap: string;
  appstagedateLoantap: any;
  lenderstage: string;
  loantaparray:  any = [];
  Loan_Tap_Normal: number=0;
  Loan_Tap_Impact: number=0;
  getmeraemistages: any = [];
  GetDocReceived: any = [];
  documentcomplete: any = [];
  loantabLendername :string;
  loantabMessage:string;

  LoantabDate:string;
  LoantabStatus:string;
  crm_api_call_id:string;
  lapp_id:string;
  lontap_error:string;
  betncolour:string;
  crmstageFilter: any= []; 
  maincrmstageFilter: any= []; 
  userstage:string;
  feedbacklenderid: string;
  feedbackloanid: string;
  feedbackstage: string;
  hdbf_lender_id: number=0;
  hdbbtn: number=0;
  idfcapid: string;
  lenderStatus: any;
  ApiCallLenderResponse: any = [];
  ApiCallLenderResponseOption:  any = [];
  OldFeedbackMessage:  any = [];
  subFeedback:  any = [];
  hdb:  number=0;
  LoanTap:  number=0;
  fedbacklengthOld: any;
  lenderid : number=0;
  lender_btn: string;
  hdbloder: number;
  loantaploder: number;
  docPending: any = [];
  docReceived: any = [];
  lenderloantabactive: number;
  lenderHDBactive: number;
  docmesg: string;
  invalid_msg_id: any;
  doc_response_id: any;
  doc_id: any;
  doccomplete: number=1;
  CrmStage: any;
  docid: number=0;
  setcounter: number=0;
  checkedInfo: any;
  userRoleListTemp: any = [];
  userRoleListToSave: any = [];
  inputs: any;
  doccompletebtn: number=0;
  form_id: any;
  quate_feedback: any;
  feedid: any;
  uid: any;
  whatloder: number;
  intResult: any;
  meraemiStages: string;
  docPendingStatus: number=0;
  ReceivedStatus: number=0;
  docReceiveduser:  any = [];
  userdocReceived: number=0;
  loginuserid: any;
  loinfname: any;
  loginname: any;
  loancode: any;
  doclength: number=0;
  imgType: any;
  ctwaDocs:  any = [];
  rejectstatus: any;
  ctwaurl: any;
  msgid: any;
  ctwauid: any;
  pdfimg: number;
  docCategory:  any = [];
  docCategoryArray:  any = [];
  catid: number=0;
  crmstage: any;
  doclist: any = [];
  doclistArray: any = [];
  docname: any;
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private leadsService:LeadsService,
    private router:Router,
    private trackProgressService:TrackprogressService,
    private formBuilder: FormBuilder,
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,
    private lenderService:LenderService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private datePipe: DatePipe,
       private lenderContactService:LenderContactService,
  
       private SelfEmpLeadService:SelfEmpLeadService,
       private ctwaService:CtwaService,
       private loginServiceService: LoginServiceService,   
  ) { this.jstoday = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US'); }
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  getValue(event) {
    this.CrmStage =event.label;
    // console.log(this.CrmStage);
    if( event.value==="14" || event.value==="15"  || event.value==="16" || event.value==="19"){
        this.transfertabopen=0;
        this.feedbackdatepikerdisable=1;
     }else if( event.value==="18"){
       this.transfertabopen=1;
       this.feedbackdatepikerdisable=1;
     }else{
       this.transfertabopen=0;
       this.feedbackdatepikerdisable=1;
     }
 
  // console.log(this.feedbackdatepikerdisable);
 }
 
  ngOnInit() {
    this.user=this.loginServiceService.userData();
    // console.log(this.user);
     if(this.user.length !=0){
       this.loginuserid= (JSON.parse(this.user)['fb_uid']);
       this.rolecode= (JSON.parse(this.user)['rolecode']);
       this.loinfname= (JSON.parse(this.user)['first_name']);
       this.loginname= (JSON.parse(this.user)['last_name']); 
      }
    for (let i = 1; i <= 15; i++) {
      this.elements.push({ id: i, first: 'User ' + i, last: 'Name ' + i, handle: 'Handle ' + i });
    }

    this.ctwaService
    .getDocCategory()
    .subscribe( (result:any) => {   
    //  console.log(result);   return false;
            if (result.status == true  && result.response== 200) {                
              for (let i = 0; i < result.docCat.length; i++) {
                const menuarray = result.docCat[i];
              //  console.log(menuarray);
                this.docCategory.push(
                  {
                    value: menuarray.data[0].doc_catg_id,
                    label: menuarray.data[0].document_category,                         
                   
                  }
                  );
                  
              }
              this.docCategoryArray=this.docCategory;
              //this.productArray =this.getProduct;                
            }else{
              console.log('Select Doc Category  Api faile');
    
             
            }
            
          });
    this.FeedbackService.GetMeraemiStage().subscribe( (result:any) => {   

           if (result.status == true  && result.response== 200) {                
             for (let i = 0; i < result.meraemistage.length; i++) {
               const menuarray = result.meraemistage[i];
              //console.log(menuarray);
               
              // console.log(this.userdocReceived);
               if(this.userdocReceived ==1){
    
                 this.getmeraemistages.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }else{
                 if(menuarray.data[0].stage_id !="18" && menuarray.data[0].stage_id !="19" && menuarray.data[0].stage_id !="17"){
                   this.getmeraemistages.push(
                     {
                       value: menuarray.data[0].stage_id,
                       label: menuarray.data[0].meraemi_stage,                         
                       lender_short_name : menuarray.data[0].meraemi_stage, 
                     }
                     );
                 }
               }
              // console.log(menuarray.data[0].stage_id);
    
                 
                 
          
            //   console.log(menuarray.data[0].stage_id);
               if(menuarray.data[0].stage_id =='21' ||   menuarray.data[0].stage_id =='14'  || menuarray.data[0].stage_id =='16'|| menuarray.data[0].stage_id =='20' || menuarray.data[0].stage_id =='26'  ){
                 this.GetDocReceived.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }
               if(menuarray.data[0].stage_id =='25' ||  menuarray.data[0].stage_id =='26'){
                 this.documentcomplete.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }   
             }
                  this.GetDocReceivedlist =this.GetDocReceived; 
                  this.optionsmeraemistage =this.getmeraemistages;   
                  this.crmdocveryfi=this.documentcomplete;
        
    
              //    console.log(this.optionsmeraemistage); //return false;
    
    
    
           }else{
             console.log('Product Api faile');
    
            
           }
           
         });
                  
    
     this.FeedbackService
      .getproduct()
      .subscribe( (result:any) => {   
     //   console.log(result);  
              if (result.status == true  && result.response== 200) {                
                for (let i = 0; i < result.productname.length; i++) {
                  const menuarray = result.productname[i];
                //  console.log(menuarray);
                  this.getProduct.push(
                    {
                      value: menuarray.data[0].product_id,
                      label: menuarray.data[0].product_name,                         
                     
                    }
                    );
                    
                }
                this.productArray =this.getProduct;                
              }else{
                console.log('Product Api faile');

               
              }
              
            });
           
               

    this.isValue = 1;
    this.idfc=0;
  //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        )
      }
     
    
    });
    
    // validation input form
    this.registerForm = this.formBuilder.group({
        idFilter: this.formBuilder.group({
        message: ['', Validators.required],        
         toDate: ['', Validators.required],
        meraemistage: ['', Validators.required],
        productname: ['', Validators.required],
        lendername: ['', Validators.required],
        
       
      }),
      adidedit: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      crmsubstage: this.formBuilder.group({
        meraemi_stage: ['', Validators.required]
      }),
      FileReject: this.formBuilder.group({
        file_msg: ['', Validators.required]
      }),
      
      docfeedback: this.formBuilder.group({
        docrecivstage: ['', Validators.required],
        docmessage: ['', Validators.required]
      }),
         
      quatefeedback: this.formBuilder.group({
        quatemessage: ['', Validators.required],
     
      }),
      ctwaDocRec: this.formBuilder.group({
        doccatid: ['', Validators.required],
        docname: ['', Validators.required],
     
      }),
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        if(params.userstage !=undefined){
          this.feedback_Stage=params.stage;
          this.userstage=params.userstage;
      }else{
        this.userstage="Active";
        this.feedback_Stage=params.stage;
      }
        
       
//console.log(this.userstage);
//console.log(this.feedback_Stage);
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
         
         // console.log(this.decryptData);
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"user_id":parseInt(this.decryptData)}];
      //    console.log(this.custsD)
            this.cust = this.custsD["results"];
            //console.log(this.custsD["results"])
           
            this.userdetils =this.custsD;
          }else{
            this.showLoader = true;    
            this.router.navigate(['/salaried']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
      function comparer(otherArray){
        return function(current){
          return otherArray.filter(function(other){
            return other.value == current.value && other.display == current.display
          }).length == 0;
        }
      }
   
   
  //  docunent

const ctwauseid=parseInt(this.decryptData);


const uid = parseInt(this.decryptData);
this.SelfEmpLeadService.getCTWADocListing(uid).subscribe(             
  (doc:any) => {
    if(doc.status === true   && doc.response == 200 ){
      this.doclength=doc.ctwa.length;
      for (let i = 0; i < doc.ctwa.length; i++) {
        const element = doc.ctwa[i];
        this.imgType=element.data[0].ctwa_doc_url;
       const docname= element.data[0].ctwa_doc_name;
        let urlParts = docname.split('.');
       let file_type= urlParts[1];
    
        
        console.log(file_type);
        this.ctwaDocs.push(
          {
           
            created_on: element.data[0].created_on, 
            ctwa_doc_name: element.data[0].ctwa_doc_name, 
            ctwa_doc_url:  element.data[0].ctwa_doc_url,
           doc_catg_id:  element.data[0].doc_catg_id, 
           doc_id:  element.data[0].doc_id, 
            message:  element.data[0].message, 
            message_id:  element.data[0].message_id, 
            message_title:  element.data[0].message_title,
            message_type:  file_type,
            modified_by:  element.data[0].modified_by,
            status:  element.data[0].status,
            user_id:  element.data[0].user_id,             


           
           
          }
          );
        }
        console.log( this.ctwaDocs);
    }else{
      this.doclength=0;
    }
   // console.log(userdetilspage);
  });
 
  
    
       // console.log(this.stage);
      if(this.feedback_Stage==="IAP_Complete" || this.feedback_Stage==="IAP_Fail" || this.feedback_Stage==="IAP_Error" || (this.feedback_Stage==="IAP_Success")){
        this.loansService.GetMultipleLenderConsent(this.userdetils).subscribe(             
          (consent:any) => {
//console.log(consent);
            if(consent.status != false){
              
              for (let i = 0; i < consent.consent.length; i++) {
                const element = consent.consent[i];           
              
                this.ConsentArray.push({
                  consent_action: "Yes",
                  consent_code: "Yes"
                });
               
              }
            }else{
              this.ConsentArray.push({
                consent_action: "No",
                consent_code: "No"
              });
            }
            
          });
     
      }
        
    //  get user details page api
        this.loansService.GetLenderStage(this.userdetils).subscribe(             
          (leadres:any) => {
       
            if(leadres.status != false){
              for (let i = 0; i < leadres.lenderstage.length; i++) {
                const element = leadres.lenderstage[i];
             //console.log(element);
                if(element.elig_status ==='1'){
                  this.eligStage ="Eligibility Clear";
                  this.iapstageBack_Colour ="eligibility_clear";
                }else if(element.elig_status ==='0'){
                  this.eligStage ="Eligibility Fail";
                  this.iapstageBack_Colour ="eligibility_fail";

                }else{
                  this.eligStage ="Eligibility Pending";
                }
                this.eligArray.push({
                  eligStage: this.eligStage,
                  elig_date:element.elig_date,
                  lender_name :element.lender_name,
                  
  
  
                });
               // console.log(element);
            
              
                if(element.quest_label !="null"){
                  this.quest_label =element.quest_label;
                }else{
                  this.quest_label ='';
                }
                if(element.quest_value_text !="null"){
                  this.quest_value_text =element.quest_value_text;
                }else{
                  this.quest_value_text ='';
                }
 setTimeout(()=>{        
            this.ErrorDetils["results"] = [{"user_id":parseInt(this.decryptData),"lender_id":  parseInt(element.lender_id)}];
          
            this.eligError =this.ErrorDetils;
            this.loansService.GetEligiError(this.ErrorDetils).subscribe(             
               (leadres:any) => {
                 //console.log(element);
                if(element.elig_status ==='1'){
                  this.eligStage ="Eligibility Clear";
                  this.eligcheck=1;
                  this.iapstageBack_Colour ="eligibility_clear";
                 this.EligInteraktstage=1;
              
                }else if(element.elig_status ==='0'){
                  this.eligStage ="Eligibility Fail";
                  this.iapstageBack_Colour ="eligibility_fail";
                  this.eligcheck=0;

                }else{
                  this.eligStage ="Eligibility Pending";
                  this.eligcheck=0;
                }
             
              //this.EligInteraktstage =this.interaktstagependingElig;
             // console.log("el"+':'+ this.EligInteraktstage);
               // console.log("Elig"+':'+this.EligInteraktstage);
                //console.log(leadres);
                if(leadres.status==true){
                  if(leadres.eligFial[0].quest_value ==="1"){
                    this.eligerrorstage=1;
                  }else if(leadres.eligFial[0].quest_value==="2"){
                    this.eligerrorstage=1;
                  }else if(leadres.eligFial[0].quest_value==="3"){
                    this.eligerrorstage=1;
                  }else if(leadres.eligFial[0].quest_value==="4"){
                    this.eligerrorstage=1;
                  }else{
                    this.eligerrorstage=0;
                  }
                }else{
                  this.eligerrorstage=100; 
                }
               
                this.lenderStage.push({ 
                  
                  lender_name:element.lender_name,
                  reason_status_change: element.reason_status_change,
                  eligStage: this.eligStage,
                  iapstageBack_Colour:this.iapstageBack_Colour,
                  ErrorDetils:leadres.eligFial, 
                  eligerrorstage:this.eligerrorstage, 
                  eligcheck:this.eligcheck,
                  eliginterakt:this.interaktstagependingElig
                 });
              //  
               });
              },4000); 
             
              }
              //
            }else{
              console.log("not any Data");
            }
          });

         
         
          let crmstages = { results: [{"user_id":this.decryptData}] };
        
         // details['results']; Crm stage
              this.lenderService.GetCrmStage(crmstages).subscribe((result: any) => { 
              
                if (result.status == true && result.response== 200 && result.crmstage[0].data[0].meraemi_stage !="no") {
                 
                  this.crmDate=result.crmstage[0].data[0].created_on;
                  this.crmStage= result.crmstage[0].data[0].meraemi_stage; 
       
                }else{
                  if(this.feedback_Stage==="IAP_Pending"){
                    this.crmDate="NA";
                    this.crmStage= "IAP Pending"; 
                  }else if(this.feedback_Stage==="IAP_Complete"){
                    this.crmDate="NA";
                    this.crmStage= "Pending Review"; 
                  }
                 
                }
              });

                     //lender sub stage
              
       
      let userdetilspage = {
       
          results: [{
            "user_id":parseInt(this.decryptData),            
            "loan_code":"",          
            "psid": "",
            "facebook_id": ""
            
                
          }]
      
       
      };
      
    //console.log(userdetilspage);
          this.SelfEmpLeadService.getLoanDetByUser(userdetilspage).subscribe( 
            
            (leadres:any) => {
             // console.log(leadres); //return false;
              if(leadres.status === true   && leadres.response == 200 ){
                this.lenderid=leadres.data[0].loans[0].lenderDetails.lender_id;
               
                this.leadDetails =leadres['data'];
                const product=this.leadDetails[0]['loans'][0]['loanDetails'];
                const pro_code=product.loan_code;
                var array = pro_code.split('BL');
                this.loancode=product.product_code+array[1];
              //  console.log(product); //return false;
                this.provider = 'Whatsapp';
                
               // console.log(this.provider);
                this.leadDetails.forEach(element => {
             
                  // console.log(element);
                  let monthlyIncome = Math.round(element.loans[0].employmentDetails.net_monthly_income);
    
                  let lender_id =element.loans[0].lenderDetails.lender_id;
                  let product_id = element.loans[0].loanDetails.product_id;
    
                 //console.log(lender_id)
                  this.custsD["results"] = [{
                    "user_income":monthlyIncome.toString(),
                    "lender_id":lender_id,
                    "product_id":product_id,
                  }];
                
                 // console.log(this.custsD)
                 let tenure = element.loans[0].loanDetails.loan_tenure;
                 let principal = element.loans[0].loanDetails.loan_amount;
                 if(this.interest_rate != undefined  && tenure != undefined  && principal != undefined ){
                   this.ProposedEmi = this.calculateEMI(this.interest_rate,tenure,principal);
                 }
                  if(isNaN(this.ProposedEmi)){
                   this.ProposedEmi = 0;
                 }
                 let existingEmi = element.loans[0].employmentDetails.total_emi_paid;
                 
                 if(existingEmi != undefined && existingEmi != 0){
                   this.e_emi = Math.round(existingEmi);
         
                 }else{
                   this.e_emi = 0;
                 }
                
                 this.foir = this.calculateFOIR(this.e_emi,this.ProposedEmi,monthlyIncome);
                 
                 if(element.loans[0].app_stage == 2 ){
                   this.iapstage = "IAP Pending";
                   this.mapstage = "1";
                   this.mapiapstage ="IAP";
                   this.criteriafailstage=0;
                   this.lenderapiFailstatus=0;
                   this.interaktIapPending=1;
                   this.interaktstage=0;
                 }else if(element.loans[0].app_stage ==3){
           
                   if(this.feedback_Stage==="IAP_Complete"){
                     this.iapstage ="IAP Complete";
                     this.iapstageBackColour ="IAP_Complete";
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.criteriafailstage=0;
                   }else  if(this.feedback_Stage==="IAP_Fail"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage ="IAP Fail";
                     this.iapstageBackColour ="IAP_Fail";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.criteriafailstage=0;
                   }else  if(this.feedback_Stage==="IAP_Error"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage ="IAP Error";
                     this.iapstageBackColour ="IAP_Error";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell"
                     this.criteriafailstage=0;
                   }else if(this.feedback_Stage==="IAP_Success"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "IAP Success";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.criteriafailstage=0;
                   }else if(this.feedback_Stage==="IAP_Pending"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "IAP Pending";
                     this.iapstageBackColour ="IAP_Pending";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.criteriafailstage=0;
                   }else{
                     this.iapstage ="IAP Complete";
                     this.iapstageBackColour ="IAP_Complete";
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.criteriafailstage=0;
                   }
                   
                  
                   
                 }else if(element.loans[0].app_stage =="4"){
                   if(this.feedback_Stage==="Document_Upload"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Document Upload";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                   }else{
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Document Upload Pending";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                   }
                  
                 }else if(element.loans[0].app_stage =="5"){
                   if(this.feedback_Stage==="Meeting_Scheduler"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Meeting Schedule";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                   }else{
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Not Meeting Schedule";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                   }
                 }else if(element.loans[0].app_stage =="6"){
                   if(this.feedback_Stage==="Success"){
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Success";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                   }else{
                     this.interaktstage=1;
                     this.interaktIapPending=0;
                     this.iapstage = "Not Success";
                     this.iapstageBackColour ="IAP_Success";
                     this.mapstage = "1";
                     this.mapiapstage ="IAP";
                     this.lenderExcelldown="excell";
                     this.feedback_Stage
                   }
                 }
                 
                 if(this.interaktIapPending !=1 && this.EligInteraktstage !=1){
                    this.IapInteraktstage =this.interaktstage;
                 }else if( this.EligInteraktstage !=1 && this.interaktstage !=1){
                     this.IapInteraktstage =this.interaktIapPending;
                 }
                 
                 
               // console.log("iamp"+':'+ this.IapInteraktstage);
              //  console.log("Iap pending"+':'+this.IapInteraktstage);                        
                   
         
         
               //  console.log(this.leadDetails);
                 if(element.loans[0].Idfc_response){
                   ///iap stage 
                 
                  
                 
                 this.trxStatus = element.loans[0].Idfc_response.trx_status;
                 this.trxSubStatus = element.loans[0].Idfc_response.trx_substatus;
             
                 //check user trackprogress status btn enable disable
                 if(element.loans[0].Idfc_response.trx_status == "DDE" || element.loans[0].Idfc_response.trx_status == "QDE" || element.loans[0].Idfc_response.trx_status == "Data Verification"){
                   this.isTrackProgress = this.isTrackProgress;
             
                 }else{
                   this.isTrackProgress = !this.isTrackProgress;
                 }
             
                 //request and response json btn enable disable
             
                 if(element.loans[0].Idfc_response.request_file_name == '' || element.loans[0].Idfc_response.request_file_name == 'null'  || element.loans[0].Idfc_response.request_file_name == 'undefined'){
                   this.requestJsonDisabled = this.requestJsonDisabled;
                 }else{
                   this.requestJsonDisabled = !this.requestJsonDisabled;
                 }
               
                
                 if(element.loans[0].Idfc_response.response_file_name == '' || element.loans[0].Idfc_response.response_file_name == "null" && element.loans[0].Idfc_response.response_file_name != 'undefined'){
                   this.responseJsonDisabled = this.responseJsonDisabled;
                 }else{
                   this.responseJsonDisabled = !this.responseJsonDisabled;
             
                 }
                 if(element.loans[0].Idfc_response.trackprogress_file_name == '' || element.loans[0].Idfc_response.trackprogress_file_name == "null" && element.loans[0].Idfc_response.trackprogress_file_name != 'undefined'){
                   this.trackProgressJsonDisabled = this.trackProgressJsonDisabled;
                 }else{
                   this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
             
                 }
                 
               }else{
                
                 this.isTrackProgress = !this.isTrackProgress;
                 this.requestJsonDisabled = !this.requestJsonDisabled;
                 this.responseJsonDisabled = !this.responseJsonDisabled;
                 this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
               }
                  
                   // console.log(element.loans[0].elig_fail_quest)
                    if(element.loans[0].elig_fail_quest){
                      for (let index = 0; index < element.loans[0].elig_fail_quest.length; index++) {
                        const value = element.loans[0].elig_fail_quest;
                       //console.log(element.loans[0].elig_fail_quest.length)
                       value.forEach(result=> {
                        
                          // console.log(result.quest_id)
                          // console.log(result.quest_value)
                          // console.log(element.loans[0].loanDetails.loan_amount)
                          // if(result.quest_id == 14){
                          //   console.log(element.loans[0].loanDetails.loan_amount)
                          // }
                        });
                      
                      }
                    }
                    if(element.loans[0].documents){
                      for (let index = 0; index < element.loans[0].documents.length; index++) {
                        const elements = element.loans[0].documents;
                       // console.log(elements)
                        elements.forEach(result=> {
                          if(result['DocTypeId'] !=undefined){
                            if(result['DocTypeId'].length < element.loans[0].documents.length){
                            //  console.log(true)
                              this.showDocuments=true;
                            }else{
                           //   console.log(false)
                              this.showDocuments=false;
                            }
                          }
                         
                        });
                      
                      }
                    }
                  
                
                });
                //main console
                if(this.leadDetails[0].loans[0].Idfc_response !=undefined){
                  this.jsonresp =1;
                }else{
                  this.jsonresp =0;
                }
         
                 //    console.log(this.leadDetails);
                if(this.leadDetails[0].loans[0].loanDetails.lender_name ==="Offline"){
                 // console.log(this.leadDetails[0].loans[0].loanDetails.lender_name);
                  //  
                  this.iapstage ="IAP Complete";
                  this.iapstageBackColour ="iap_complete";
                    this.mapstage = "1";
                      this.lenderOfflineResponse =0;
                      this.mapiapstage ="IAP";
                      this.lenderExcelldown="excell";
              
                  this.idfcbackcolour="Data_Verification";
                    
                    this.lenderResponse.push(
                      {
                        errorInfo: '', 
                        iap_date: this.leadDetails[0].loans[0].iapDetails.iap_date,
                        lenderHeder: this.leadDetails[0].loans[0].loanDetails.lender_name,
                        sfdcid:"1",
                        responseDate: this.leadDetails[0].loans[0].iapDetails.iap_date,
                        status: "",
                        lenderstage:this.iapstage,
                        substatus: "",
                        Heder: this.leadDetails[0].loans[0].loanDetails.lender_name,
                        backcolour:  this.idfcbackcolour,
                                        
                        lender_id: "1",
                        
                  
                      }
                      );
                   }else{
                    this.lenderOfflineResponse =1;
                   }


                   
                 
               //  console.log(this.leadDetails);
                if(this.leadDetails[0].loans[0].fullerton_response !=undefined){
                  this.fullerton_lender_id=22;
                  
                  if(this.leadDetails[0].loans[0].fullerton_response.status==="UMBER"){
                    this.appstageflu = "IAP Success";
                  }else  if(this.leadDetails[0].loans[0].fullerton_response.status==="RED"){
                    this.appstageflu ="IAP Fail";
                  }else  if(this.leadDetails[0].loans[0].fullerton_response.status==="GREEN"){
                    this.appstageflu ="IAP Success";
                  }
                  
                  this.lenderResponse.push(
                    {
                      errorInfo: '', 
                      iap_date: this.leadDetails[0].loans[0].fullerton_response.iap_date,
                      lenderHeder: this.leadDetails[0].loans[0].fullerton_response.lenderHeder+'-'+this.leadDetails[0].loans[0].fullerton_response.hubble_id,
                      sfdcid:this.leadDetails[0].loans[0].fullerton_response.hubble_id,
                      responseDate: this.leadDetails[0].loans[0].fullerton_response.responseDate,
                      status: this.leadDetails[0].loans[0].fullerton_response.status,
                      lenderstage:this.appstageflu,
                      substatus: this.leadDetails[0].loans[0].fullerton_response.substatus,
                      Heder: this.leadDetails[0].loans[0].fullerton_response.lenderHeder,
                      backcolour:this.leadDetails[0].loans[0].fullerton_response.status,
                      lender_id:this.leadDetails[0].loans[0].fullerton_response.fullerton_lender_id,
                      
                    }
                    );
                 }else{
                  this.fullerton_lender_id=0;
                 }
                if(this.leadDetails[0].loans[0].IdfcResponse != undefined){
                  this.idfc_lender_id=this.leadDetails[0].loans[0].IdfcResponse.idfc_lender_id;
                
                  if(this.leadDetails[0].loans[0].IdfcResponse.status=== "Data Verification"){
                    this.appstage = "IAP Success";
                    this.idfcbackcolour="Data_Verification";
                  }else if(this.leadDetails[0].loans[0].IdfcResponse.status==="Cancel"){
                    this.appstage ="IAP Error";
                    this.idfcbackcolour="Cancel";
                  }else if(this.leadDetails[0].loans[0].IdfcResponse.status==="Reject"){
                    this.idfcbackcolour="Reject";
                    this.appstage ="IAP Fail";
                  }else if(this.leadDetails[0].loans[0].IdfcResponse.status==="Data Verification Pending"){
                    this.appstage = "IAP Success";
                    this.idfcbackcolour="Data_Verification";
                  }else if(this.leadDetails[0].loans[0].IdfcResponse.errorInfo ==="java.util.concurrent.TimeoutException: Timeout exceeded"){
                    this.appstage = "IAP Error";
                    this.idfcbackcolour="api_error";
                  }


                 // errorInfo: "java.util.concurrent.TimeoutException: Timeout exceeded"
                  
                  else{
                    this.idfcbackcolour=this.leadDetails[0].loans[0].IdfcResponse.status;
                  }
                  if(this.leadDetails[0].loans[0].IdfcResponse.sfdc_id ===undefined){
                    this.idfcapid="NA";
                  }else{
                    this.idfcapid=this.leadDetails[0].loans[0].IdfcResponse.sfdc_id;
                  }
                //  console.log(this.leadDetails[0].loans[0].IdfcResponse.status);
                  if(this.leadDetails[0].loans[0].IdfcResponse.status === undefined){
                    this.lenderStatus=this.leadDetails[0].loans[0].IdfcResponse.errorInfo;
                  }else{
                    this.lenderStatus=this.leadDetails[0].loans[0].IdfcResponse.status;
                  }
                 // console.log(this.leadDetails[0].loans[0].IdfcResponse.status);
           
                  this.lenderResponse.push(
                    {
                      errorInfo: this.leadDetails[0].loans[0].IdfcResponse.errorInfo, 
                      iap_date: this.leadDetails[0].loans[0].IdfcResponse.iap_date,
                      lenderHeder: this.leadDetails[0].loans[0].IdfcResponse.lenderHeder+'-'+this.idfcapid,
                      // sfdcid:this.leadDetails[0].loans[0].IdfcResponse.sfdc_id,
                      sfdcid:this.idfcapid,
                      Heder: this.leadDetails[0].loans[0].IdfcResponse.lenderHeder,
                      responseDate: this.leadDetails[0].loans[0].IdfcResponse.responseDate,
                      status: this.lenderStatus,
                      substatus: this.leadDetails[0].loans[0].IdfcResponse.substatus,
                      backcolour:this.idfcbackcolour,
                      lenderstage:this.appstage,
                      lender_id: this.leadDetails[0].loans[0].IdfcResponse.idfc_lender_id,
                      
                    }
                    );
                }else{
                  this.idfc_lender_id=0;
                }
                if(this.leadDetails[0].loans[0].loanTab_response !=undefined)  {
                  this.loantab_lender_id=this.leadDetails[0].loans[0].loanTab_response.loantab_lender_id;
                  if(this.leadDetails[0].loans[0].loanTab_response.loantap_status ==="success"){
                    this.appstageLoantap ="IAP Submit";
                    this.idfcbackcolour="Data_Verification";
                  }else if(this.leadDetails[0].loans[0].loanTab_response.loantap_status ==="Rejected"){
                    this.idfcbackcolour="Reject";
                    this.appstageLoantap ="IAP Fail";
                  }else if(this.leadDetails[0].loans[0].loanTab_response.loantap_status ==="Channel App"){
                    this.idfcbackcolour="Reject";
                    this.appstageLoantap ="IAP Error";
                  }else if(this.leadDetails[0].loans[0].loanTab_response.loantap_status ==="Credit Appraisal"){
                    this.appstageLoantap = "IAP Success";
                    this.idfcbackcolour="Data_Verification";
                  }else if(this.leadDetails[0].loans[0].loanTab_response.status === "Internal Server Error:Exception caught in getLoanUserDetails() null"){
                    this.appstageLoantap = "IAP Error";
                    this.idfcbackcolour="api_error";
                  }
               //   console.log(this.leadDetails[0].loans[0].loanTab_response.status);
                  this.lenderResponse.push(
                    {
                      errorInfo: '', 
                      iap_date: this.leadDetails[0].loans[0].loanTab_response.iap_date,
                      lenderHeder: this.leadDetails[0].loans[0].loanTab_response.lenderHeder1+'-'+this.leadDetails[0].loans[0].loanTab_response.lenderHeder2,
                      sfdcid:this.leadDetails[0].loans[0].loanTab_response.lenderHeder2,
                      responseDate: this.leadDetails[0].loans[0].loanTab_response.responseDate,
                      status: this.leadDetails[0].loans[0].loanTab_response.status,
                      substatus: this.leadDetails[0].loans[0].loanTab_response.substatus,                     
                      Heder: this.leadDetails[0].loans[0].loanTab_response.lenderHeder1,
                      lender_id: this.leadDetails[0].loans[0].loanTab_response.loantab_lender_id,
                      backcolour:  this.idfcbackcolour,
                      lenderstage: this.appstageLoantap,

                    }
                    );
                }else{
                  this.loantab_lender_id=0;
                }
                if(this.leadDetails[0].loans[0].hdbf_response !=undefined){
                  this.hdbf_lender_id=31;
                  
                  if(this.leadDetails[0].loans[0].hdbf_response.status==="UMBER"){
                    this.appstageflu = "IAP Success";
                  }else  if(this.leadDetails[0].loans[0].hdbf_response.status==="Red"){
                    this.appstageflu ="IAP Fail";
                  }else  if(this.leadDetails[0].loans[0].hdbf_response.status==="GREEN"){
                    this.appstageflu ="IAP Success";
                  }
                  
                  this.lenderResponse.push(
                    {
                      errorInfo: this.leadDetails[0].loans[0].hdbf_response.substatus, 
                      iap_date: this.leadDetails[0].loans[0].hdbf_response.iap_date,
                      lenderHeder: this.leadDetails[0].loans[0].hdbf_response.lenderHeder+'-'+this.leadDetails[0].loans[0].hdbf_response.hubble_id,
                      sfdcid:this.leadDetails[0].loans[0].hdbf_response.hubble_id,
                      responseDate: this.leadDetails[0].loans[0].hdbf_response.responseDate,
                      status: this.leadDetails[0].loans[0].hdbf_response.status,
                      lenderstage:this.appstageflu,
                      substatus: this.leadDetails[0].loans[0].hdbf_response.substatus,
                      Heder: this.leadDetails[0].loans[0].hdbf_response.lenderHeder,
                      backcolour:this.leadDetails[0].loans[0].hdbf_response.status,
                      lender_id:"31",
                      
                    }
                    );
                 }else{
                  this.hdbf_lender_id=0;
                 }
                 //console.log(this.lenderResponse);
               if(this.lenderResponse.length ===0){
                 if(this.leadDetails[0].loans[0].lenderDetails.lender_name=== "FULLERTON INDIA"){
                   this.lenderapiFail= "Fullerton India Api Error";
                   this.lenderapiFailstatus=1;
                 }else if(this.leadDetails[0].loans[0].lenderDetails.lender_name=== "IDFC First Bank"){
                  this.lenderapiFail= "IDFC First Bank Api Error";
                  this.lenderapiFailstatus=1;
                 }else if(this.leadDetails[0].loans[0].lenderDetails.lender_name=== "LoanTap"){
                  this.lenderapiFail= "Loan Tap Api Error";
                  this.lenderapiFailstatus=1;
                 }
               }else{
                this.lenderapiFailstatus=0;
               }
                           
                  //  console.log(this.lenderResponse);
                  if(this.lenderResponse.length ===0){
                        if(this.leadDetails[0].loans[0].lenderDetails.lender_id==="43"){
                          this.idfcbackcolour="Reject";
                          this.lenderResponse.push(
                            {
                              lenderstage :"IAP Submit",
                              lenderHeder: "LoanTap"+'-'+"NA",
                              sfdcid:"43",                   
                              lender_id: "43",
                              backcolour:    this.idfcbackcolour,
                            
        
                            }
                            );
                        }else  if(this.leadDetails[0].loans[0].lenderDetails.lender_id==="22"){
                          this.idfcbackcolour="Reject";
                          this.lenderResponse.push(
                            {
                              lenderstage :"IAP Submit",
                              lenderHeder: "FULLERTON INDIA"+'-'+"NA",
                              sfdcid:"22",                   
                              lender_id: "22",
                              backcolour:    this.idfcbackcolour,
                            
        
                            }
                            );
                        }else  if(this.leadDetails[0].loans[0].lenderDetails.lender_id==="53"){
                          this.idfcbackcolour="Reject";
                          this.lenderResponse.push(
                            {
                              lenderstage :"IAP Submit",
                              lenderHeder: "IDFC First SFDC"+'-'+"NA",
                              sfdcid:"53",                   
                              lender_id: "53",
                              backcolour:    this.idfcbackcolour,
                            
        
                            }
                            );
                        }
                  }
                    // console.log(this.leadDetails[0].loans[0].userDetails);
                    
                      let current_date = new Date();
               
                  if(this.leadDetails[0].loans[0].eligStage.elig_date !="NA"){
                    let elDate= this.leadDetails[0].loans[0].eligStage.elig_date;
                    let currentDate = this.datePipe.transform(current_date, 'yyyy-MM-dd');
                    let eligDate =this.datePipe.transform(elDate, 'yyyy-MM-dd');
                    if(currentDate === eligDate){
                      this.logindate =this.leadDetails[0].loans[0].eligStage.elig_date;
                      this.loindateExp=1;
                      this.kysdocback="kysdocbackTrue";
                    }else{
                      this.logindate =this.leadDetails[0].loans[0].eligStage.elig_date;
                      this.loindateExp=0;
                      this.kysdocback="kysdocbackFalse";
                    }
                    
                  }else{
                   this.loindateExp=2;
                  }
               
                
                 
               
              
              
              
              
              
              }
              

              //console.log( this.lenderResponse);
            });
            let details =  { results: [{ "user_id":this.decryptData}] } ;
            details['results']; 
             
              this.FeedbackService.postfeedback_getcrm(details)
              .subscribe((result: any) => {  
               
                if (result.status == true) {
                 
                  for (let i = 0; i < result.feedvalue.length; i++) {
                    const element = result.feedvalue[i];
           
                   if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        docPending:"1"
                
                      });
                
                
                
                  }
                   if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        docPending:"1"
                
                      });
                
                
                
                  }
                  
                  
                  
                  if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        Received:"1"
                
                      });
                  
                  }
                 
                 
                  if(element.data[0].lender_name == "LoanTap"){
                    this.lenderloantabactive=1;
                  }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                    this.lenderHDBactive=1;
                  }
                 
                    if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                      this.OldFeedbackMessage.push(
                        {
                         
                          admin_name: element.data[0].admin_name, 
                          feedback_id: element.data[0].feedback_id, 
                          feedback_message:  element.data[0].feedback_message, 
                          followupdate:  element.data[0].follow_update, 
                          interactiondate:  element.data[0].interactiondate, 
                          stage:  element.data[0].stage, 
                          user_id:  element.data[0].user_id, 
                          status:  element.data[0].status,

                         
                         
                        }
                        );
                    }else{
                      if(element.data[0].stage === "21" ){
                        this.docReceiveduser.push({
                          docReceived : '1'
                        }
                        );
                       
                      }else{
                        this.docReceiveduser.push({
                          docReceived : '0'
                        }
                        );
                      }

                      this.elements_feedback.push(
                        {
                          admin_name: element.data[0].admin_name, 
                          feedback_id: element.data[0].feedback_id, 
                          feedback_message:  element.data[0].feedback_message, 
                          followupdate:  element.data[0].follow_update, 
                          interactiondate:  element.data[0].interactiondate, 
                          stage:  element.data[0].stage, 
                          user_id:  element.data[0].user_id, 
                          status:  element.data[0].status,
                          meraemi_stage:  element.data[0].meraemi_stage,
                          lender_name:  element.data[0].lender_name,
                          product_name:  element.data[0].product_name,
                          
                        }
                        );
                    }


                    
                   
                     
                    
  
                      
                  }
                  for (let i = 0; i <  this.docReceiveduser.length; i++) {
                    const docrec = this.docReceiveduser[i];
                    if(docrec.docReceived !='0'){
                      this.userdocReceived=1;
                     
                    }

                    
                  }
                  

                 
                  this.docPendingStatus=this.docPending[0]['docPending'];
                  this.ReceivedStatus=this.docPending[0]['Received'];
                 
                if(this.elements_feedback.length !=0){
                  this.fedbacklength =this.elements_feedback.length;
                }else{
                  this.popupaddBtn =0;
                 
                }
                  
                 /// console.log(this.elements_feedback.length); 
              //  console.log(this.OldFeedbackMessage);
              //  console.log(this.elements_feedback);
               // this.popupbtnactive=this.meraemiStage[0].stage;
                 //console.log(this.elements_feedback.length);
                  
                  this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                  this.message = result.message;
                   
                   
                }else{
                  this.popupaddBtn =0;
                  //("hello");
                }
              }); 
              let Getdetails =  { results: [{ "user_id":this.decryptData}] } ;
              Getdetails['results']; 
              this.FeedbackService.GetSubfeedbackComment(Getdetails)
              .subscribe((results: any) => {
                if (results.status == true) {
                  for (let i = 0; i < results.subfeed.length; i++) {
                    const elements = results.subfeed[i];
                    this.subFeedback.push(
                      {
                       
                        admin_name: elements.data[0].admin_name, 
                        feedback_id: elements.data[0].feedback_id, 
                        sub_feedback_id: elements.data[0].sub_feedback_id,
                        sub_message:  elements.data[0].sub_message, 
                        interaction_date:  elements.data[0].interaction_date, 
                        user_id:  elements.data[0].user_id,
                        
            
                       
                       
                      }
                      );
                   //  console.log( results);
                  }
                }
              });
          //  console.log(this.lenderid);
            this.lenderContactService
            .getLenderName()
            .subscribe( (result:any) => {   
                   //console.log(result);
                   
                    if (result.status = 'true') {                
                      for (let i = 0; i < result.lender_name.length; i++) {
                        const menuarray = result.lender_name[i];
                       //console.log(menuarray);
                      // console.log(this.lenderid);
                      this.messages.push(
                        {
                          value: menuarray.data[0].lender_id,
                          label: menuarray.data[0].lender_name,  
                          lender_short_name : menuarray.data[0].lender_short_name,                    
                         
                        }
                        );
                      
                        
                          //this.optionsArray =this.messages;
                      }
                        
                      this.mainlenderarrayfilter=this.messages;  
                      //console.log(this.mainlenderarrayfilter);
                     
      
      
      
                    }else{
                      console.log('Lender Api faile');
                    }
                    
                  });
           
               

         
            let userdetails = { results: [{"user_id":this.decryptData }] };
            userdetails['results']; 
           // console.log(userdetails);
            this.FeedbackService.GetWhatsapUserdata(userdetails)
            .subscribe((result: any) => {
           // console.log(result);
              if(result.response=== 200 &&    result.status=== true){
                this.whatstage=1; 
                this.adminname=result.WhatsapUser[0].data[0].admin_name;
                this.whatssenduserdate=result.WhatsapUser[0].data[0].created_on;
                this.whatsapmessage=result.WhatsapUser[0].data[0].campain_name;
                this.whatstatus=result.WhatsapUser[0].data[0].status;
                this.intResult=result.WhatsapUser[0].data[0].result;
               // console.log(result.WhatsapUser[0].data[0].admin);
              }else{
                console.log("Whataap No any Response !");
              }
              
              
            });
    }
    
    adidSubmitted: boolean = false;
   
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }
  get fc() { return (<FormGroup>this.registerForm.get('adidedit')).controls; }
  get cr() { return (<FormGroup>this.registerForm.get('crmsubstage')).controls; }
  get fr() { return (<FormGroup>this.registerForm.get('FileReject')).controls; }
  get df() { return (<FormGroup>this.registerForm.get('docfeedback')).controls; }
  get qf() { return (<FormGroup>this.registerForm.get('quatefeedback')).controls; }
  get ctd() { return (<FormGroup>this.registerForm.get('ctwaDocRec')).controls; }
  get ctwaDocRec() {
    return this.registerForm.get('ctwaDocRec');
  }
  get quatefeedback() {
    return this.registerForm.get('quatefeedback');
  }
  get docfeedback() {
    return this.registerForm.get('docfeedback');
  }
  get FileReject() {
    return this.registerForm.get('FileReject');
  }
  get crmsubstage() {
    return this.registerForm.get('crmsubstage');
  }
  get adidedit() {
    return this.registerForm.get('adidedit');
  }
  get idFilter() {
    return this.registerForm.get('idFilter');
  }
  onChange1(email:string, isChecked: boolean) {
   // console.log(email);
    if(isChecked) {
      this.checkFormArray.push(email);
    } else {
      let index = this.checkFormArray.indexOf(email);
      this.checkFormArray.splice(index,1);
    }
}

Addid(){
  this.user_id=this.decryptData;
  let adname=this.adidedit.value.adid;
  let detail = { results: [{ "user_id":this.user_id, "adid":adname}] };
  detail['results']; 
  this.FeedbackService.updateUserAdId(detail).subscribe((result: any) => {
    if(result.status===true  && result.response== 200){
      this.display = "none";
      alert("ad id update!");
    }
   /// console.log(result);
  });
  
}
feedbackPopup(data1){

this.feedbackloanid=data1;
 
 // console.log(data2);
  this.FeedbackPopup=1;
 
}
filterUserId(){
  //console.log(this.idFilter.value.meraemistage);
 // console.log(this.CrmStage); return false; 
//console.log(this.idFilter.value.productname);  
                    if(this.idFilter.value.meraemistage ==="15"){
                      
                      this.meraemiStages="SE CRM Cancel";
                    }else if(this.idFilter.value.meraemistage ==="14"){
                      
                      this.meraemiStages="SE Cust Cancel";
                    }else if(this.idFilter.value.meraemistage ==="18"){
                      
                      this.meraemiStages="SE Transfer";
                    }else if(this.idFilter.value.meraemistage ==="16"){
                      
                      this.meraemiStages="SE CRM Decline";
                    }else{
                    this.meraemiStages=this.CrmStage;
                    }

                  if(this.idFilter.value.meraemistage ==="18"){
                    this.status =parseInt(this.idFilter.value.meraemistage);
                    this.feedbackLendername =(Number(this.idFilter.value.lendername));
                    
                   // this.feedbackProductname =this.idFilter.value.productname;
                  }else if(this.idFilter.value.meraemistage !="18"){
                    this.status =parseInt(this.idFilter.value.meraemistage);
                    this.feedbackLendername =0;

                  }else{
                    alert("Pl Select Meraemi Stage !");
                    this.idFilter.reset();
                  }

                    //console.log(this.feedbackLendername);
                    //console.log(this.status);
                    if(this.leadDetails[0].loans[0].loanDetails.loan_id === undefined){
                      this.loanid=this.feedbackloanid;

                    }else{
                      this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
                    }
                    //message
                    if(this.idFilter.value.message !='' || this.idFilter.value.message !=undefined || this.idFilter.value.message !=null){
                      this.message =this.idFilter.value.message;
                    }else{
                        alert("Pl Input Feedback Message !");
                        this.idFilter.reset();
                    }
                  
                    this.feedbackProductname ="4";
                    


                    var currentdate = new Date();
                    // adjust 0 before single digit date
                    let dates = ("0" + currentdate.getDate()).slice(-2);
                    // current month
                    let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
                    // current year
                    let year = currentdate.getFullYear();
                    this.currentdate =(year + "-" + month + "-" + dates);

                    //console.log(this.idFilter.value.toDate);
                    if(this.idFilter.value.toDate !="" || this.idFilter.value.toDate !=null){
                     let followupdate =this.idFilter.value.toDate;

                        if(this.currentdate <= followupdate){
                          this.followupdate =this.idFilter.value.toDate;
                          //console.log("1");
                        }else{
                          this.followupdate =this.currentdate;
                  
                        }
                     
                    }else{
                     this.followupdate =this.currentdate;

                    }
                    
                
                    // console.log(this.feedbackLendername);
                    // console.log(this.status);
                    // console.log(this.loanid);
                    // console.log(this.message);
                    // console.log(this.followupdate);
                    console.log(this.CrmStage);
                         
                  
  
                  this.user_id=parseInt(this.decryptData);
                  let details = {
                    
                      results: [{
                        "message":this.message,            
                        "interactiondatetime":this.currentdate,          
                        "status": this.status,
                        "user_id":this.user_id,
                        "admin_name":this.loinfname,                      
                        "loan_id":this.loanid,
                        "followupdate":this.followupdate,
                        "feedbackProductname" :this.feedbackProductname,
                        "feedbackLendername":this.feedbackLendername,
                        "CrmStage":this.meraemiStages,
                         "campstatus":"1"
                      }]
                  
                    
                  };
            //  console.log(details);   return false;     
                  details['results']; 
                      this.FeedbackService.postfeedback(details)
                      .subscribe((result: any) => { 
                            //  console.log(result);
                                      if (result.status === true && result.response===200 ) {
                                        this.idFilter.reset();
                                        this.transfertabopen=0;
                                                  this.FeedbackPopup =0;
                                                  this.elements_feedback.length=0;
                                                 
                                                 
                                                let details =  { results: [{ "user_id":this.user_id}] } ;
                                                details['results']; 
                                                this.FeedbackService.postfeedback_getcrm(details)
                                                .subscribe((result: any) => {  
                                                  //console.log(result);
                                                  if (result.status == true) {
                                                   console.log(result);
                                                    for (let i = 0; i < result.feedvalue.length; i++) {
                                                      const element = result.feedvalue[i];
                                                     
                                                      if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            docPending:"1"
                                                      
                                                          });
                                                      
                                                      
                                                      
                                                      }
                                                       if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            docPending:"1"
                                                      
                                                          });
                                                      
                                                      
                                                      
                                                      }
                                                      
                                                      if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            Received:"1"
                                                      
                                                          });
                                                      
                                                      }
                                                     
                                                      
                                                      if(element.data[0].lender_name == "LoanTap"){
                                                        this.lenderloantabactive=1;
                                                      }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                                                        this.lenderHDBactive=1;
                                                      }else{
                                                        console.log("call other lender");
                                                      }
                                                      if(element.data[0].status == "1"){
                                                        this.crmDate=element.data[0].interactiondate;
                                                        this.crmStage= element.data[0].meraemi_stage;
                                                      }
                                                      // console.log(element.data[0].status);
//console.log(this.crmStage);
                                                      if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                                                       
                                                    
                                                       
                                                         
                                                        this.OldFeedbackMessage.push(
                                                          {
                                                           
                                                            admin_name: element.data[0].admin_name, 
                                                            feedback_id: element.data[0].feedback_id, 
                                                            feedback_message:  element.data[0].feedback_message, 
                                                            followupdate:  element.data[0].follow_update, 
                                                            interactiondate:  element.data[0].interactiondate, 
                                                            stage:  element.data[0].stage, 
                                                            user_id:  element.data[0].user_id, 
                                                            status:  element.data[0].status,
                                  
                                                           
                                                           
                                                          }
                                                          );
                                                      }else{
                                                        this.elements_feedback.push(
                                                          {
                                                            admin_name: element.data[0].admin_name, 
                                                            feedback_id: element.data[0].feedback_id, 
                                                            feedback_message:  element.data[0].feedback_message, 
                                                            followupdate:  element.data[0].follow_update, 
                                                            interactiondate:  element.data[0].interactiondate, 
                                                            stage:  element.data[0].stage, 
                                                            user_id:  element.data[0].user_id, 
                                                            status:  element.data[0].status,
                                                            meraemi_stage:  element.data[0].meraemi_stage,
                                                            lender_name:  element.data[0].lender_name,
                                                            product_name:  element.data[0].product_name,
                                                          }
                                                          );
                                                      }
                                  
                                  
                                                      
                                                     
                                                       
                                                      
                                    
                                                        
                                                    }
                                                   
                                                   
                                                   
                                                    this.docPendingStatus=this.docPending[0]['docPending'];
                                                    this.ReceivedStatus=this.docPending[0]['Received'];
                                                    
                                                      
                                                    //console.log(this.OldFeedbackMessage);
                                                  //console.log(this.elements_feedback);
                                                 
                                                   //console.log(this.popupbtnactive);
                                                    this.fedbacklength =this.elements_feedback.length;
                                                    this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                                                    this.message = result.message;
                                                    this.popupaddBtn=1;
                                                     
                                                  }else{
                                                    this.popupaddBtn =0;
                                                    //("hello");
                                                  }
                                                }); 
                                                
                                      }else {
                                        let details = {
                                          
                                            results: [{
                                              "message":this.message,            
                                              "interactiondatetime":this.currentdate,          
                                              "status": this.status,
                                              "user_id":this.user_id,
                                              "admin_name":this.loinfname,                      
                                              "loan_id":this.loanid,
                                              "followupdate":this.followupdate,
                                              "feedbackProductname" :this.feedbackProductname,
                                              "feedbackLendername":this.feedbackLendername,
                                              "emilSendStage":"feedbak"
                                            }]
                                        
                                        
                                        };
                                        
                                     //  console.log(details);                 
                                        details['results'];
                                        this.FeedbackService.SendEmailAdminUserTransfer(details)
                                        .subscribe((result: any) => {
                                        console.log(result);
                                        });
                                      }
                                      //meraemi stage
                                      
                                    
                                     
                                     
                });
   
}
 
resetfeedback() {
     this.FeedbackPopup =0;
      this.idFilter.reset();
  }
  onCloseHandledone() {
    this.display = "none";
  }
    backmenu(){
      this.showLoader = false;
      setTimeout(()=>{    
        //this.showLoader = true;
        this.router.navigate(['/MeraEMI-All-Users-Leads']);
      }, 2000);
     
      
      }
     
     
  calculateEMI(interest:number,tenure:number,principal:number){
    var emi = 0;
    var a = (1 + interest / 1200);
    var b = Math.pow(a, tenure);
    var c = Math.pow(a, tenure) - 1;
    emi = Math.round(principal * (interest / 1200) * b / c);
    //console.log(emi)
    return emi;
  }
  calculateFOIR(existemi:number,proposedemi:number,income:number){
    var foir= 0;
   // console.log(existemi)
   // console.log(proposedemi)
  //  console.log(income)
    foir = ((existemi + proposedemi) / income * 100);
   // console.log(foir)
    return foir;
  }
  trackProgress(data){
    // console.log(data)
    // return false;
    data.sfdc_id = "0328568170";
    this.trackData["results"]= {
      "record_id":"0328568170",
      "email_send": "NO",
      "sms_send": "NO",
      //"user_id":107
    };

    this.isTrackProgress = !this.isTrackProgress;

    setTimeout(()=>{  
      
      this.isTrackProgress = true;
     // console.log('set disable '+this.isTrackProgress)
      this.trackProgressService.updateTrackProgress(this.trackData)
      .subscribe(result => {
       // console.log(result)
          
        if(result.status == 1){
          //console.log(result.trx_status)
          if(result.trx_status != 'undefined'){
            this.trxStatus = result.trx_status;
            this.trxSubStatus =result.trx_substatus;
            //console.log(this.setBtnDisable);
            
              if(result.trx_status == "DDE" || result.trx_status == "QDE" || result.trx_status == "Data Verification"){
               // console.log(result.trx_status)
                this.isTrackProgress = this.isTrackProgress;
                //console.log(this.isTrackProgress)
              }else{
                this.isTrackProgress = !this.isTrackProgress;
               // console.log(this.isTrackProgress)
              }
          }  
        }
        
      });
    }, 20000);
  

  }
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  //idfc bank json downlods file
  downloadRequestFile(filename){  

    saveAs("./assets/UserRequestResponse/idfc/Request/"+filename+".json",filename);
   
  
  }
  downloadResponseFile(filename){  

    saveAs("./assets/UserRequestResponse/idfc/Response/"+filename+".json",filename);
  
  }
  downloadTrackProgressFile(filename){   
 
   saveAs("./assets/UserRequestResponse/idfc/trackprogress/"+filename+".json",filename);
  
  } 

  //loan tab json downlods file
  downloadRequestFileLoantab(filename){  
   
    saveAs("./assets/UserRequestResponse/LoanTap/Request/"+filename+".json",filename);
 
  
  }
  downloadResponseFileLoantab(filename){  
   
    saveAs("./assets/UserRequestResponse/LoanTap/Response/"+filename+".json",filename);
      }
  downloadTrackProgressFileLoantab(filename){   
  
   saveAs("./assets/UserRequestResponse/LoanTap/Trackprogress/"+filename+".json",filename);
  
  }
   //Fullerton json downlods file
   downloadRequestFileFullerton(filename){  
   
    saveAs("./assets/UserRequestResponse/fullerton/Request/"+filename+".json",filename);
 
  
  }
  downloadResponseFileFullerton(filename){  
   
    saveAs("./assets/UserRequestResponse/fullerton/Response/"+filename+".json",filename);
      }
  downloadTrackProgressFileFullerton(filename){   
  console.log(filename);
   saveAs("./assets/UserRequestResponse/fullerton/CallBacks/"+filename+".json",filename);
  
  }
  //HDB json downlods file
  downloadRequestFileHDB(filename){  
   
    saveAs("./assets/UserRequestResponse/HDBF/Request/"+filename+".json",filename);
 
    
  }
  
  downloadResponseFileHDB(filename){  
   
    saveAs("./assets/UserRequestResponse/HDBF/Response/"+filename+".json",filename);
      }
      downloadTrackProgressFileHDB(filename){   
  console.log(filename);
   saveAs("./assets/UserRequestResponse/HDBF/CallBacks/"+filename+".json",filename);
  
  }
  
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone(data) {
    this.adid=data;
    console.log(data);
   
    this.display = "block";
 
    this.valuemain=1;
    this.userid =1;
    this.isValue = 1;
  }
  

toggle1() { 
  alert('1');
  this.isValue = 1;
   this.idfc=1;
  
   this.document=0;
   this.stage=0;
   
}
toggle6() { 
  alert('2');
  this.isValue = 2;
   this.idfc=1;
  
   this.document=0;
   this.stage=0;
   
}
toggle2() { 
  alert('3');
  this.isValue = 2;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle5() { 
  alert('4');
  this.isValue = 1;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle3() { 
  alert('5');
  this.isValue = 3; 
  this.idfc= 0;

  this.document=1;
  this.stage=0;
}
toggle4() { 
  alert('6');
  this.isValue = 4;
  this.idfc= 1;
 
  this.document=0;
  this.stage=1;

}

valueChange(value) {
  //console.log(value);
  if(value !=undefined){
    this.remainingText = 300 - value.length;
  //console.log(this.remainingText);
  }
  
  
 }

 onClick(check){
      //console.log(check);
      if(check==1){
        this.tab = 'tab1';
        this.tabval=1;
      }else if(check==2){
        this.tab = 'tab2';
        this.tabval=2;
       // console.log(this.cust);
      }else if(check==3){
        this.tab = 'tab3';
        this.tabval=3;
       // console.log(this.cust);
      }     
   // alert(this.tabval);
  }
 onSwitch(check){
 
  switch (check) {
   case 1:
     return 'tab1';
   case 2:
     return 'tab2';
   
 }
}
getapencard(el){
  // console.log(el);
   this.title=el;
   this.imageur="../../../assets/documents/pencard.png";
   this.css="border_clas";
 }
 getadharcard(el){
   this.title=el;
   this.imageur="../../../assets/documents/adharcard.png";
   this.css="border_clas";
 }
   //user Details
   ExcellDetails(user){
   
    let stage=   this.feedback_Stage;
   // 
   this.useid = this.cryptojsService.encryptData((user));  
  // console.log(this.useid);  
   //this.decryptData = this.cryptojsService.decryptData(this.useid); 
  // console.log(this.decryptData);    
   const base_url=location.origin+"/#/MeraEMI-mis-send-lender?data=";        
   const detailsurl = base_url + this.useid.toString();
   const dateur=detailsurl+"&stage="+stage.toString();
  
   //console.log(detailsurl);
   window.open(dateur, "_blank");
}

clickEvent(data1,data2,data3,data4,data5,data6,data7,data8,data9){
  let userId=data1;
  let phoneNumber=data2;
  let countryCode=data3;
  let name=(data5+' '+data6);
  let email=data4;
  this.user_id=this.decryptData;
  var today = new Date();
  let currentdateandtime=(today.toISOString());
  
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
   this.followupdate =(date+' '+time);
  //console.log(event.toISOString());
  let details = {
    data: {
      results: [{
        "userId":userId,            
        "phoneNumber":phoneNumber,          
        "countryCode": countryCode,
        "name": name,
        "email": email,
        "createdAt": currentdateandtime
        
            
      }]
  
    }
  };
  
 
   // console.log(details);
     details['data']['results']; 
     this.FeedbackService.postwhatsap(details)
     .subscribe((result: any) => { 
      if(result.result===true){
        let details = {
          data: {
            results: [{
              "user_id":userId,
              "message":result.message,            
              "admin":this.loinfname,          
              "status": "1",
              "created_by": "1",
              "created_on": this.followupdate,
                  
            }]
        
          }
        };
        //console.log(details);
        details['data']['results']; 
        this.FeedbackService.postwhatsapInsertdataadmin(details)
        .subscribe((result: any) => { 
          if(result.response=== 200 && result.status=== true){
            //console.log(this.userdetils);
            let details = {
              data: {
                results: [{
                  "user_id":userId,                  
                      
                }]
            
              }
            };
            details['data']['results']; 
            this.FeedbackService.GetWhatsapUserdata(details)
            .subscribe((result: any) => {
            //  console.log(result);
              if(result.response=== 200 &&    result.status=== true){
                this.whatstage=1; 
                this.adminname=result.WhatsapUser[0].data[0].admin;
                this.whatssenduserdate=result.WhatsapUser[0].data[0].created_on;
                this.whatsapmessage=result.WhatsapUser[0].data[0].message;
                this.whatstatus=result.WhatsapUser[0].data[0].status;
                this.intResult=result.WhatsapUser[0].data[0].result;
              }
              
              
            });
          }
        });
      }else{ //main if close
        let details = {
         
            results: [{
              "user_id":userId,
              "message":result.message,            
              "admin":this.loinfname, 
              "phoneNumber":phoneNumber,
              "name": name,
              "email": email,
              "createdAt": currentdateandtime,
              "emilSendStage":"Insert"
            }]
        
        };
        details['results']; 
        this.FeedbackService.SendEmailAdminUserTransfer(details)
        .subscribe((result: any) => {
        console.log(result);
        });


      }
      
      
        
     });//main function  close
  
}
DetDocumentPage(data){
  let stage=  data.file_name;
  this.usewebContentLinkid =data.webViewLink; 
  const filetype = this.usewebContentLinkid.split("?"); 
  const docname=filetype[0];
  const str=filetype[0];
  const newstr = str.replace("view","preview"); 
 const driveurl = this.cryptojsService.encryptData(newstr);
        const base_url=location.origin+"/#/Meraemi-Document-View?data=";        
        const detailsurl = base_url + driveurl.toString();
       
        const dateur=detailsurl+"&stag="+stage.toString();
       
        //console.log(dateur);
        window.open(dateur, "_blank");
//console.log(data);
}

whataapBtn(){
  this.whataapelig=1;
}
Cancel(){
   this.whataapelig =0;
}

docrecevied(){
  this.doc_recevied=1;
}









  DocReceived(){
    if(this.crmsubstage.value.meraemi_stage !=''){
     if(this.crmsubstage.value.meraemi_stage =='21'){
       this.status=parseInt(this.crmsubstage.value.meraemi_stage);
       this.message="Update on Doc Complete Stage";
     }else if(this.crmsubstage.value.meraemi_stage =='14'){
          this.status=parseInt(this.crmsubstage.value.meraemi_stage);
         this.message="Update on Cust Cancel Stage";
       
     }else if(this.crmsubstage.value.meraemi_stage =='15'){
                  this.status=parseInt(this.crmsubstage.value.meraemi_stage);
       this.message="Update on CRM Cancel Stage";
     
     }else if(this.crmsubstage.value.meraemi_stage =='16'){
                  this.status=parseInt(this.crmsubstage.value.meraemi_stage);
       this.message="Update on CRM Decline Stage";          
     }else if(this.crmsubstage.value.meraemi_stage =='20'){
       this.status=parseInt(this.crmsubstage.value.meraemi_stage);
         this.message="Update on Doc Pending Stage";          
       }

}else{
alert("Select crm Stage !...");
this.crmsubstage.reset();
}
// console.log(this.crmsubstage.value.meraemi_stage);  return false;  
this.docRecive.hide();
this.user_id=parseInt(this.decryptData);
this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
this.feedbackProductname ="4";
this.feedbackLendername =0;
var currentdate = new Date();
// adjust 0 before single digit date
let dates = ("0" + currentdate.getDate()).slice(-2);
// current month
let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
// current year
let year = currentdate.getFullYear();
this.currentdate =(year + "-" + month + "-" + dates);
this.followupdate ="0000-00-00 00:00:00";

 let details = {
   
     results: [{
       "message":this.message, 
       "status":this.status,
       "user_id":this.user_id,
       "admin_name":this.loinfname,                      
       "loan_id":this.loanid,      
       "feedbackProductname" :this.feedbackProductname,
       "feedbackLendername":this.feedbackLendername,
       "interactiondatetime":this.currentdate,  
       "followupdate":this.followupdate,
       "CrmStage":this.CrmStage,
      "campstatus":"1"

     }]
 
   
 };
//console.log(details);       //return false;     
 details['results']; 
 this.FeedbackService.postfeedback(details)
.subscribe((result: any) => { 
// console.log(result); return false;     
         if (result.status === true && result.response===200 ) {
           this.idFilter.reset();
           this.transfertabopen=0;
                     this.FeedbackPopup =0;
                     this.elements_feedback.length=0;
                    
                    
                   let details =  { results: [{ "user_id":this.user_id}] } ;
                   details['results']; 
                   this.FeedbackService.postfeedback_getcrm(details)
                   .subscribe((result: any) => {  
                    // console.log(result);
                     if (result.status == true) {
                      //console.log(result);
                       for (let i = 0; i < result.feedvalue.length; i++) {
                         const element = result.feedvalue[i];
                         

                            if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                              this.docPending.push(
                                { 
                                  docPending:"1"

                                });


                            }

                         
                               if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                 this.docPending.push(
                                   { 
                                     docPending:"1"

                                   });


                               }



                               if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                 this.docPending.push(
                                   { 
                                     Received:"1"

                                   });

                               }








                         if(element.data[0].lender_name == "LoanTap"){
                           this.lenderloantabactive=1;
                         }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                           this.lenderHDBactive=1;
                         }else{
                           console.log("call other lender");
                         }
                         if(element.data[0].status == "1"){
                           this.crmDate=element.data[0].interactiondate;
                           this.crmStage= element.data[0].meraemi_stage;
                         }
                          console.log(element.data[0].status);
                          console.log(this.crmStage);
                         if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                          
                       
                          
                            
                           this.OldFeedbackMessage.push(
                             {
                              
                               admin_name: element.data[0].admin_name, 
                               feedback_id: element.data[0].feedback_id, 
                               feedback_message:  element.data[0].feedback_message, 
                               followupdate:  element.data[0].follow_update, 
                               interactiondate:  element.data[0].interactiondate, 
                               stage:  element.data[0].stage, 
                               user_id:  element.data[0].user_id, 
                               status:  element.data[0].status,
     
                              
                              
                             }
                             );
                         }else{
                           this.elements_feedback.push(
                             {
                               admin_name: element.data[0].admin_name, 
                               feedback_id: element.data[0].feedback_id, 
                               feedback_message:  element.data[0].feedback_message, 
                               followupdate:  element.data[0].follow_update, 
                               interactiondate:  element.data[0].interactiondate, 
                               stage:  element.data[0].stage, 
                               user_id:  element.data[0].user_id, 
                               status:  element.data[0].status,
                               meraemi_stage:  element.data[0].meraemi_stage,
                               lender_name:  element.data[0].lender_name,
                               product_name:  element.data[0].product_name,
                             }
                             );
                         }
     
     
                         
                        
                          
                         
       
                           
                       }
                      
                      
                      
                    
                       
                       this.docPendingStatus=this.docPending[0]['docPending'];
                       this.ReceivedStatus=this.docPending[0]['Received'];
                    
                      //console.log(this.popupbtnactive);
                       this.fedbacklength =this.elements_feedback.length;
                       this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                       this.message = result.message;
                       this.popupaddBtn=1;
                        
                     }else{
                       this.popupaddBtn =0;
                       //("hello");
                     }
                   }); 
                   
         }else {
           let details = {
             
               results: [{
                 "message":this.message,            
                 "interactiondatetime":this.currentdate,          
                 "status": this.status,
                 "user_id":this.user_id,
                 "admin_name":this.loinfname,                      
                 "loan_id":this.loanid,
                 "followupdate":this.followupdate,
                 "feedbackProductname" :this.feedbackProductname,
                 "feedbackLendername":this.feedbackLendername,
                 "emilSendStage":"feedbak"
               }]
           
           
           };
           
        //  console.log(details);                 
           details['results'];
           this.FeedbackService.SendEmailAdminUserTransfer(details)
           .subscribe((result: any) => {
           console.log(result);
           });
         }
         //meraemi stage
         
       
        
        
});

 }


    docuplods(){
      this.user_id=this.decryptData;   
      const docurl = "https://doc.meraemi.com/document/upload";     
      const dateur=docurl+"/"+this.user_id.toString();
      window.open(dateur, "_blank");
      console.log(this.user_id);  
    }
 



    docVeryFiyCancle(){
      this.docVeryFiNo.hide();
      this.FileReject.reset();
    }

    docveryfiyes(data,count){
    
      this.setcounter ++;
      console.log(this.setcounter); return false;
      

    }
    onChangeRole(doc, isChecked) {
      this.checkedInfo = isChecked;
      let toatalcountDoc=this.drivelength;
    //  console.log(this.checkedInfo); //return false;
      if (isChecked.target.checked) {
    
          this.setcounter ++;
      } else {
        
          this.setcounter --;
      }
      console.log(this.setcounter);
      if(toatalcountDoc===this.setcounter){
        this.doccompletebtn=1;
        this.docVeryFiYes.show();
        this.status="25";
      // this.message="Update on Doc Complete Stage";  
        this.user_id=this.decryptData;
        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
        this.feedbackProductname ="4";
    
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        this.followupdate ="0000-00-00 00:00:00";
      }else{
        this.doccompletebtn=0;
      }

      //console.log(this.setcounter); return false;
    }

    uncheck() {
        this.inputs.forEach(check => {
            check.nativeElement.checked = false;
        });
    }
    copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
    docverify(data){
      //console.log();
     //   console.log(this.drivelength); return false;
        this.doc_id="0";
        this.status=this.docfeedback.value.docrecivstage;  
        this.message=this.docfeedback.value.docmessage;  
        this.user_id=this.decryptData;
        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
        this.feedbackProductname ="4";
     
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        this.followupdate ="0000-00-00 00:00:00";
        let details = {
              
          results: [{
            "feedbackProductname" :this.feedbackProductname,
            "user_id":this.user_id,
            "admin_name":this.loinfname,
            "message":this.message, 
            "interactiondatetime":this.currentdate, 
            "loan_id":this.loanid,    
            "status":this.status,
            "CrmStage":"Doc Review",      
            "doc_id":this.doc_id,  
            "campstatus":"1"
            
          }]
      
        
      };
       
      }
      docReceivedUser(data,evnt){
      
    
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
                               
                        
         if(evnt.isTrusted===true){
                        this.user_id=this.decryptData;
                        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
                        
                        this.feedbackProductname ="4";
                        this.feedbackLendername =0;
                        let details = {
                          
                            results: [{
                              "message":"doc Received User",            
                              "interactiondatetime":this.currentdate,          
                              "status": data,
                              "user_id":this.user_id,
                              "admin_name":this.loinfname,                      
                              "loan_id":this.loanid,
                              "followupdate":this.currentdate,
                              "feedbackProductname" :this.feedbackProductname,
                              "feedbackLendername":this.feedbackLendername,
                              "CrmStage":"doc Received",
                               "campstatus":"1"
                            }]
                        
                          
                        };
                    //console.log(details);       return false;     
                        details['results']; 
                            this.FeedbackService.postfeedback(details)
                            .subscribe((result: any) => { 
                                    console.log(result);
                                            if (result.status === true && result.response===200 ) {
                                              this.idFilter.reset();
                                              this.transfertabopen=0;
                                                        this.FeedbackPopup =0;
                                                        this.elements_feedback.length=0;
                                                       
                                                       
                                                      let details =  { results: [{ "user_id":this.user_id}] } ;
                                                      details['results']; 
                                                      this.FeedbackService.postfeedback_getcrm(details)
                                                      .subscribe((result: any) => {  
                                                        console.log(result);
                                                        if (result.status == true) {
                                                         console.log(result);
                                                          for (let i = 0; i < result.feedvalue.length; i++) {
                                                            const element = result.feedvalue[i];


                                                            if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  docPending:"1"
                                                            
                                                                });
                                                            
                                                            
                                                            
                                                            }
                                                             if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  docPending:"1"
                                                            
                                                                });
                                                            
                                                            
                                                            
                                                            }
                                                            
                                                            if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  Received:"1"
                                                            
                                                                });
                                                            
                                                            }
                                                            if(element.data[0].lender_name == "LoanTap"){
                                                              this.lenderloantabactive=1;
                                                            }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                                                              this.lenderHDBactive=1;
                                                            }else{
                                                              console.log("call other lender");
                                                            }
                                                            if(element.data[0].status == "1"){
                                                              this.crmDate=element.data[0].interactiondate;
                                                              this.crmStage= element.data[0].meraemi_stage;
                                                            }
                                                             console.log(element.data[0].status);
                                                             console.log(this.crmStage);
                                                            if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                                                             
                                                          
                                                             
                                                               
                                                              this.OldFeedbackMessage.push(
                                                                {
                                                                 
                                                                  admin_name: element.data[0].admin_name, 
                                                                  feedback_id: element.data[0].feedback_id, 
                                                                  feedback_message:  element.data[0].feedback_message, 
                                                                  followupdate:  element.data[0].follow_update, 
                                                                  interactiondate:  element.data[0].interactiondate, 
                                                                  stage:  element.data[0].stage, 
                                                                  user_id:  element.data[0].user_id, 
                                                                  status:  element.data[0].status,
                                        
                                                                 
                                                                 
                                                                }
                                                                );
                                                            }else{
                                                              this.elements_feedback.push(
                                                                {
                                                                  admin_name: element.data[0].admin_name, 
                                                                  feedback_id: element.data[0].feedback_id, 
                                                                  feedback_message:  element.data[0].feedback_message, 
                                                                  followupdate:  element.data[0].follow_update, 
                                                                  interactiondate:  element.data[0].interactiondate, 
                                                                  stage:  element.data[0].stage, 
                                                                  user_id:  element.data[0].user_id, 
                                                                  status:  element.data[0].status,
                                                                  meraemi_stage:  element.data[0].meraemi_stage,
                                                                  lender_name:  element.data[0].lender_name,
                                                                  product_name:  element.data[0].product_name,
                                                                }
                                                                );
                                                            }
                                        
                                        
                                                            
                                                           
                                                             
                                                            
                                          
                                                              
                                                          }
                                                         
                                                         
                                                         
                                                       
                                                          this.docPendingStatus=this.docPending[0]['docPending'];
                                                          this.ReceivedStatus=this.docPending[0]['Received'];
                                                       
                                                         //console.log(this.popupbtnactive);
                                                          this.fedbacklength =this.elements_feedback.length;
                                                          this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                                                          this.message = result.message;
                                                          this.popupaddBtn=1;
                                                           
                                                        }else{
                                                          this.popupaddBtn =0;
                                                          //("hello");
                                                        }
                                                      }); 
                                                      
                                            }else {
                                              let details = {
                                                
                                                  results: [{
                                                    "message":this.message,            
                                                    "interactiondatetime":this.currentdate,          
                                                    "status": this.status,
                                                    "user_id":this.user_id,
                                                    "admin_name":this.loinfname,                      
                                                    "loan_id":this.loanid,
                                                    "followupdate":this.followupdate,
                                                    "feedbackProductname" :this.feedbackProductname,
                                                    "feedbackLendername":this.feedbackLendername,
                                                    "emilSendStage":"feedbak"
                                                  }]
                                              
                                              
                                              };
                                              
                                           //  console.log(details);                 
                                              details['results'];
                                              this.FeedbackService.SendEmailAdminUserTransfer(details)
                                              .subscribe((result: any) => {
                                              console.log(result);
                                              });
                                            }
                                            //meraemi stage
                                            
                                          
                                           
                                           
                      });
         }else{
           console.log("Other stage");
         }
      }
      quetesfeedback(data,userid){
        this.feedbackquate.show();
        this.feedid=data;
        this.uid=userid;
      //  console.log(data);
      }
      quatefeedbackInsert(){
       // undefined
      
        if(this.quatefeedback.value.quatemessage !=undefined){
              this.quate_feedback= this.quatefeedback.value.quatemessage;
              let details = { results: [{ "message":this.quate_feedback ,"feedid":this.feedid ,"user_id":this.uid, "admin_name":this.user.first_name   }]};
           //   console.log(details); return false;
              details['results']; 
              this.FeedbackService.postSubfeedbackComment(details)
              .subscribe((result: any) => { 
                  if(result.status==true){
                    let Getdetail =  { results: [{ "user_id":this.decryptData}] } ;
                    Getdetail['results']; 
                    this.FeedbackService.GetSubfeedbackComment(Getdetail)
                    .subscribe((results: any) => {
                      this.subFeedback.length=0;
                      this.feedbackquate.hide();
                      this.quatefeedback.reset();
                      if (results.status == true) {
                        for (let i = 0; i < results.subfeed.length; i++) {
                          const elements = results.subfeed[i];
                          this.subFeedback.push(
                            {
                             
                              admin_name: elements.data[0].admin_name, 
                              feedback_id: elements.data[0].feedback_id, 
                              sub_feedback_id: elements.data[0].sub_feedback_id,
                              sub_message:  elements.data[0].sub_message, 
                              interaction_date:  elements.data[0].interaction_date, 
                              user_id:  elements.data[0].user_id,
                              
                  
                             
                             
                            }
                            );
                          // console.log( results);
                        }
                      }
                    });
                  }
               // console.log(result);
              
            });
          
        }
    }
    callIntPai(data){
            var date = new Date();
            var transformDate = this.datePipe.transform(date, 'yyyy-MM-dd');
            let details = {
                                
              results: [{
                "user_id":data.user_id,            
                "phoneNumber":data.mobile_number,          
                "countryCode": "+91",
                "name":data.first_name+' '+data.last_name,
                "email":data.email_address,                      
                "campain_name":"SEECDM1",
                "meraemistage":"Eligibility Clear",
                "createdAt" :transformDate,
                "campain_id" :"29",
                "crmstage" :"3",
                "channel_id" :"4",
                "admin" :"Admin",
                "feedmsg" :"User send to Self Emp interakt",
                
              
                
                
                
              }]
          
            
          };
          console.log(details);
          this.SelfEmpLeadService.SelfempUserSendToInt(details)
          .subscribe((result: any) => { 
            console.log(result);
              if(result.response ==200 &&  result.status ==true){
                this.whatloder=1;
                setTimeout(()=>{ 
                  this.whatstage=1;
                  let userdetails = { results: [{"user_id":this.decryptData }] };
                  userdetails['results']; 
                  // console.log(userdetails);
                  this.FeedbackService.GetWhatsapUserdata(userdetails)
                  .subscribe((result: any) => {
                   console.log(result);
                    if(result.response=== 200 &&    result.status=== true){
                      this.whatstage=1; 
                      this.adminname=result.WhatsapUser[0].data[0].admin_name;
                      this.whatssenduserdate=result.WhatsapUser[0].data[0].created_on;
                      this.whatsapmessage=result.WhatsapUser[0].data[0].campain_name;
                      this.whatstatus=result.WhatsapUser[0].data[0].status;
                      this.intResult=result.WhatsapUser[0].data[0].result;
                    // console.log(result.WhatsapUser[0].data[0].admin);
                    }else{
                      console.log("Whataap No any Response !");
                    }
                    
                    
                  });
                  this.whatloder=1;
                }, 2000);
              }else{
                console.log("api not call!");
              }
     
           
                 
          });
     // console.log(data);
    }
    intraktuserlisting(data){
      // console.log(data); return false;
    
        
        const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
       

       const dateur=base_url+"91"+data.toString();
      
       // //console.log(detailsurl);
        window.open(dateur, "_blank");
        
  
      }
      previewDocpage(data){
        this.rejectstatus=data.status;
        
        this.imgType=data.ctwa_doc_url;
        let urlParts = this.imgType.split('%2F');
  
       let file_type = data.message_type;
        
        
        
   //    const fileExtension: string =  this.imgType.split('.').pop()?.toLowerCase() || '';
//
      //  console.log(fileExtension);
        if(file_type=="pdf"){
          this.ctwaurl=data.ctwa_doc_url;
        // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
          this.msgid=data.message_id;
          this.ctwauid=data.user_id;
          this.docView.show();
          this.pdfimg=1;
        }else{
          this.pdfimg=0;
          this.ctwaurl=data.ctwa_doc_url;
          // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
           this.msgid=data.message_id;
           this.ctwauid=data.user_id;
           this.docView.show();
        }
        
        //console.log(this.pdfimg);
       // console.log(this.ctwaurl);
        //console.log(this.msgid);
        //console.log(this.ctwauid); return false;

      this.ctwaurl=data.ctwa_doc_url;
     // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
      this.msgid=data.message_id;
      this.ctwauid=data.user_id;
      this.docView.show();
        
    }
    docAccept(){
      this.docView.hide();
      this.docCat.show();
    }
    docRejects(){
      this.docView.hide()
      this.docReject.show();
    }
    selectValue(event) {
      //console.log(event);
         this.catid =event.value;
         this.crmstage=event.label;
         this.ctwaService.getDocId(this.catid)
         .subscribe((result:any) => {
           if (result.status == true  && result.response== 200) { 
             for (let i = 0; i < result.docid.length; i++) {
               const menuarray = result.docid[i];
               this.doclist.push(
                 {
                   // value: menuarray.data[0].doc_id,
                   label: menuarray.data[0].dscription,                         
                   value: menuarray.data[0].dscription,  
                 }
                 );
              
             }
             this.doclistArray=this.doclist;
             console.log( this.doclistArray);   return false;
             }else{
               console.log('Select Doc   Api faile');
             }
          });
      
       // console.log(this.feedbackdatepikerdisable);
      }
      
ctwaDocReceived(){

  this.docname=this.ctwaDocRec.value.docname;
  let details = {
    
    results: [{
      "docname":this.ctwaDocRec.value.docname, 
      "status":"2",
      "userid":this.decryptData,
      "msgid":this.msgid
  
      
    }]

  
};

details['results']; 
this.ctwaService.ctwaDocReceived(details)
.subscribe((result: any) => { 
  if(result.response===200 && result.status===true){
    this.docCat.hide();
    this.doclength=0;
    this.ctwaDocs=[];
    const uid = parseInt(this.decryptData);
    this.ctwaService.getCTWADocListing(uid).subscribe(             
      (doc:any) => {
        if(doc.status === true   && doc.response == 200 ){
          this.doclength=doc.ctwa.length;
          for (let i = 0; i < doc.ctwa.length; i++) {
            const element = doc.ctwa[i];
            this.imgType=element.data[0].ctwa_doc_url;
            let urlParts = this.imgType.split('%2F');
          let img= urlParts[3];
          let file_type = img.split('&');
            
            console.log(file_type[0]);
            this.ctwaDocs.push(
              {
              
                created_on: element.data[0].created_on, 
                ctwa_doc_name: element.data[0].ctwa_doc_name, 
                ctwa_doc_url:  element.data[0].ctwa_doc_url,
                message:  element.data[0].message, 
                message_id:  element.data[0].message_id, 
                message_title:  element.data[0].message_title,
                message_type:  file_type[0],
                modified_by:  element.data[0].modified_by,
                status:  element.data[0].status,
                user_id:  element.data[0].user_id,             


              
              
              }
              );
            }
            console.log( this.ctwaDocs);
        }else{
          this.doclength=0;
        }
      // console.log(userdetilspage);
      });
  }else{
    this.docCat.hide();
  }
  //console.log(result);
});

}
}
